import React from "react";
import styled from 'styled-components';

const ChatContentList = styled.div`
  width: 100%;
  max-width: 700px;
  position: relative;
  margin: 0px auto;
  padding: 0.5rem 0.5rem 1.8rem 0.5rem;
  color: #000000;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  .chat-content-container {
    padding-bottom: 1.8rem;
  }
  button {
    display: block;
    background-color: #c1f7e7;
    flex: 0;
    padding: 5px;
    border-radius: 5px;
    color: #263238;
    font-weight: 100;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    min-width: 40%;
    border: 2px solid #08DE9E;
    cursor: pointer;
    &:hover {
      background-color: #08DE9E;
    }
  }
`;

export default ChatContentList;