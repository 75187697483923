import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import Header from '../Header';
import Footer from '../Footer';
import GlobalContext from '../../../context/GlobalContext';
import imgFavicon from '../../../assets/favicon.png';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { ToastContainer } from 'react-toastify';
import { Link } from 'gatsby';
import 'antd/dist/antd.css';
import './../../../assets/fonts/fonts.css';
import './bootstrap-custom.scss';
import './app.scss';
import FreshStreamWidget from '../../../pages/careers/FreshStreamWidget';
import * as Sentry from '@sentry/gatsby';
import ModalError from './../../ModalError/ModalError';
import TwilioChat from '../TwilioChat';
import TwilioChat2 from '../TwilioChat2';
import { navigate } from 'gatsby';
const axios = require('axios');

const setting = {
  color: {
    theme: '#fff',
  },
};

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;

  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

const AgentBanner = styled.div`
  background-color: #ff4545;
  color: #fff;
  padding: 15px;
  text-align: center;
  margin-top: 10px;
  @media (max-width: 992px) {
    margin-top: 0;
  }
  a {
    color: #fff;
    text-decoration: underline;
    font-size: 1rem !important;
  }
`

const Layout = ({ children }) => {
  const gContext = useContext(GlobalContext);
  const [visibleLoader, setVisibleLoader] = useState(true);

  const logoutUser = () => {
    localStorage.removeItem('agentToken')
    localStorage.removeItem('agentTokenExpiration')
    localStorage.removeItem('emailAddress')
    gContext.setShowAgentBanner(false)
    navigate('/agent-login')
  }

  // Initialize Sentry on production website
  useEffect(async () => {
    if (typeof window !== 'undefined' && window) {
      if (window.location.pathname === "/") {
        var slug = "/index" + window.location.search;
      } else {
        var slug = window.location.pathname + '' + window.location.search;
      }
      if (window.location.pathname === '/checkout-landing' || window.location.pathname === '/checkout-landing/') {
        slug = '/netomnia' + window.location.search;
      }
      if (!sessionStorage.getItem('slug')) {
        sessionStorage.setItem('slug', slug.substring(1));
      }
    }

    if (process.env.GATSBY_ENV === 'live') {
      Sentry.init({
        dsn: 'https://7350dc3f5baffbe12220abf2481a9e52@o916048.ingest.sentry.io/4505821657563136',
        integrations: [new Sentry.BrowserTracing()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      });
    }
    // let s = null
    // s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.id="UR_initiator"
    // s.async = true;
    // s.innerHTML = `(function () { var iid = 'uriid_da39a3ee5e6b4b0d3255bfef95601890afd80709'; if (!document._fpu_) document.getElementById('UR_initiator').setAttribute('id', iid); var bsa = document.createElement('script'); bsa.type = 'text/javascript'; bsa.async = true; bsa.src = 'https://static.useresponse.com/public/youfibre/sdk/chat-'+iid+'-16.js'; (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(bsa); })();`;
    // document.head.appendChild(s);
    // return () => {
    //     document.head.removeChild(s);
    // };

    // let s = null
    // s = document.createElement('script');
    // s.src = 'https://livechat-YouFibre.connexone.co.uk/widget?connid=afb7e249-0d8e-4b4e-95c7-a4558c27fb62';
    // s.id = 'livechat-youfibre';
    // s.setAttribute('data-socket-uri', 'https://livechat-YouFibre.connexone.co.uk');
    // document.head.appendChild(s);
    // return () => {
    //     document.head.removeChild(s);
    // };
  }, []);

  useLayoutEffect(() => {
    AOS.init();
    setVisibleLoader(false);
  }, []);

  const eleRef = useRef();

  useEffect(() => {
    checkAgentTokenExpiration()
  }, [])

  const checkAgentTokenExpiration = () => {
    const agentTokenExpiration = localStorage.getItem("agentTokenExpiration");
    
    if (!agentTokenExpiration) {
      gContext.setShowAgentBanner(false)
      return
    }

    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (currentTimestamp >= parseInt(agentTokenExpiration, 10)) {
        localStorage.removeItem('agentToken')
        localStorage.removeItem('agentTokenExpiration')
        localStorage.removeItem('agentEmail')
        gContext.setShowAgentBanner(false)
        return
    } else {
        gContext.setShowAgentBanner(true)
        return
    }
  }

  return (
    <>
      <Helmet>
        <title>YouFibre</title>        
        <script type="text/javascript">var _conv_prevent_bodyhide = true;</script>
        <script type="text/javascript" src={`//cdn-4.convertexperiments.com/v1/js/10041665-100415631.js?environment=${process.env.GATSBY_GTM_ENV || 'staging'}`}></script>
        <link rel="icon" type="image/png" href={imgFavicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="google-site-verification" content="f0kBGthl6aBSgvs1h2-9rbgF_lfS_HURFMYYmRCt1U0" />
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
        <script src="https://api.aklamio.com/tracking.min.js"></script>
        <script src="https://secure.adnxs.com/seg?add=34711279&t=2" type="text/javascript"></script>
      </Helmet>
      <CookieConsent
        location="bottom"
        buttonText="Accept All"
        declineButtonText="Decline"
        containerClasses="text-center text-md-left cookieConsentContainer"
        buttonWrapperClasses="cookieConsentButtons"
        cookieName="youFibreCokkieConsent"
        style={{
          color: '#000000',
          background: '#ffffff',
          paddingRight: '90px',
        }}
        buttonStyle={{
          background: '#08DE9E',
          padding: '6px 30px',
          borderRadius: '100px',
          textAlign: 'center',
          border: '1px solid #000000',
          minWidth: '147px',
        }}
        enableDeclineButton
        declineButtonStyle={{
          background: '#ffffff',
          color: '#000000',
          padding: '6px 30px',
          borderRadius: '100px',
          textAlign: 'center',
          border: '1px solid #000000',
          minWidth: '147px',
        }}
        expires={150}
        onAccept={() => {
          Cookies.set('gatsby-gdpr-google-analytics', true);
          Cookies.set('gatsby-gdpr-google-tagmanager', true);
          Cookies.set('gatsby-gdpr-facebook-pixel', true);
        }}
      >
        We use cookies to create the best experience. You can manage your preferences and{' '}
        <Link
          to="/legal/privacy-and-cookie-policy/"
          style={{ textDecoration: 'underline', color: '#000000' }}
        >
          see our cookie policy
        </Link>{' '}
        for more information.
      </CookieConsent>
      <TwilioChat />
      <TwilioChat2 id="main_chat" fullscreen={false} side='right' />
      <ModalError />
      <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
        <div className="load-circle">
          <span className="one" />
        </div>
      </Loader>
      <div className="site-wrapper overflow-x-hidden" ref={eleRef}>
        {
          gContext.headerVisible &&
          <>
            <Header isDark={gContext.headerDark} />
            {
              gContext.showAgentBanner &&
              <AgentBanner>
                You're logged in as agent: {localStorage.getItem('agentEmail')}. Click <a href="javascript:;" onClick={() => logoutUser()}>here</a> to switch user.
              </AgentBanner>
            }
          </>
        }
        <div className="page-wrapper">
          {children}
        </div>
        {
          gContext.footerVisible &&
          <Footer isDark={gContext.footerDark} />
        }
      </div>
      <ToastContainer autoClose={8000}
      />
    </>
  );
};

export default Layout;
