import React, { useContext, useEffect, useRef, useState } from "react";
import Widget from "./components/Widget";
import GlobalContext from "../../../context/GlobalContext";
import Chat from "./Chat";
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { defaultMessages, initialTwilioChatState, initialTwilioSettingsState, initialTwilioTimerState } from "./utils/chats";
import ChatWrapper from "./components/styled/ChatWrapper";
import { Message, chatLocalStorage, connectToWebsocket, findUnreadedMessages, getAllMessages, isConversationActive, settingsLocalStorage, timerLocalStorage } from "./services/chatService";

const TwilioChat2 = () => {
  const gContext: any = useContext(GlobalContext);
  const messages = useRef<Message[]>([]);
  const sc = useRef<Socket | null>(null);
  const openChat = useRef<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [side, setSide] = useState<string>('right');

  // Check if Conversation is closed in mean time
  const fetchConversationActive = async () => {
    try {
      const conversationId = await chatLocalStorage('chatConversationId');
      if(conversationId){
        const response:any = await isConversationActive(conversationId);
        if(response === "CLOSED"){
          gContext.goResetTwilioChat();
        }
      }
    } catch (error) {
      console.error("Error fetching is conversation active:", error);
    }
  };

  // On reload get chat messages from previous session / on page reload
  const fetchExistingMessages = async (onlyMessages:boolean = false) => {
      try { 
        const tmpLocalStorage = await chatLocalStorage(); 
        if(tmpLocalStorage['chatConversationId'] && tmpLocalStorage){
          const tmpMessages:any = await getAllMessages(tmpLocalStorage['chatConversationId']); 
          if(tmpMessages){
            const tmpUnreadedMessages = await findUnreadedMessages(messages.current, tmpMessages);
            if(tmpMessages && tmpLocalStorage && tmpUnreadedMessages){                          
              const tmpState = 
              onlyMessages ? 
                {
                  ...gContext.twilioChat,
                  messages: tmpMessages
                } : 
                {
                  ...initialTwilioChatState, 
                  ...tmpLocalStorage || [],
                  messages: tmpMessages
                };
              gContext.goSetTwilioTimer({
                ...gContext.twilioTimer, 
                chatUnreaded: gContext.twilioChat.chatConversationId && (document.visibilityState !== 'visible' || (!openChat.current && !fullscreen)) && Object.keys(tmpUnreadedMessages).length > 1,
              });
              gContext.goSetTwilioChat(tmpState);
              messages.current = tmpMessages;
              return tmpState;     
            }
          }
        } else {
          const tmpState = {
            ...initialTwilioChatState,
            ...tmpLocalStorage || [],
            chatUserType: null,
            openChat: fullscreen, // must be open when is full screen mode
          };  
          gContext.goSetTwilioChat(tmpState);
          messages.current = defaultMessages;
          gContext.goSetTwilioTimer({
            ...initialTwilioTimerState
          });
          return tmpState;   
        }
      } catch (error) {
          console.error("Error fetching is conversation active:", error);
      }
  };

  useEffect(() => {    
    localStorage.removeItem("twilioSettings");
    localStorage.removeItem("title");
    
    fetchConversationActive(); 
    fetchExistingMessages();

    document.addEventListener('visibilitychange', async () => {
      const response:any = await fetchExistingMessages();
      if(response){
        if (document.visibilityState === 'visible') {
          gContext.goSetTwilioChat({ 
            ...response
          });
          document.getElementById('inputMessage')?.focus();
        }
      }
    });
  }, []);
  
  useEffect(() => {
    if(typeof gContext.twilioChat.openChat !== 'undefined') openChat.current = gContext.twilioChat.openChat;
  }, [gContext.twilioChat.openChat]);
  
  useEffect(() => {
    if(gContext?.twilioSettings?.fullscreen){
      setFullscreen(gContext.twilioSettings.fullscreen);
      setSide(gContext.twilioSettings.side);
    } else {
      gContext.goSetTwilioSettings({
        ...initialTwilioSettingsState
      });
    }
  }, [gContext.twilioSettings.fullscreen]);

  useEffect(() => {
    if(gContext.twilioChat.chatUserId){
      const tmpUUID = localStorage.getItem('chatSessionId') || uuidv4();
      localStorage.setItem('chatSessionId', tmpUUID);  
      if(!sc.current) sc.current = connectToWebsocket(tmpUUID);
      sc.current?.on('connect', () => {
        console.log('Connected to WebSocket server');
      });
      sc.current?.on("connect_error", (err) => {
        console.log(`Connect error due to ${err.message}`);
        sc.current = null;
        sc.current = connectToWebsocket(gContext.twilioChat.chatUserId);
      });  
      sc.current?.on("disconnect", (reason) => {
        console.log(`Disconnected: ${reason}`);
        sc.current = null;
        sc.current = connectToWebsocket(gContext.twilioChat.chatUserId);
      });
    } else {
      gContext.goResetTwilioChat();
    }
  }, [gContext.twilioChat.chatUserId]);

  useEffect(() => {
    if(gContext.twilioChat.chatCaseId){
      // Subscribe to room with Case ID
      sc.current && gContext.twilioChat.chatCaseId && sc.current.emit("joinRoom", gContext.twilioChat.chatCaseId);  
      // Listen events only for our Case/Conversation
      sc.current && !sc.current.listeners(`case-event-${gContext.twilioChat.chatCaseId}`).length && sc.current?.on(`case-event-${gContext.twilioChat.chatCaseId}`, (data) => {    
        switch(data.type){
          case "CASE_UPDATED": 
            gContext.twilioChat.agentId = data.data.properties?.OwnerId;
            // gContext.goSetTwilioChat({...gContext.twilioChat, agentId: gContext.twilioChat.agentId});
            break;
          case "CONVERSATION_CLOSED":
            gContext.goSetTwilioChat({
              ...initialTwilioChatState,
              openChat: data.data.properties.ClosedBy === 'AGENT' || gContext.twilioChat.openChat,
              chatClosing: data.data.properties.ClosedBy !== 'CUSTOMER'
            });
            gContext.goSetTwilioTimer({
              ...initialTwilioTimerState
            });
            gContext.twilioChat.chatCaseId && sc?.current?.emit("leaveRoom", gContext.twilioChat.chatCaseId);
            break;
          case "MESSAGE_CREATED": 
            gContext.goSetTwilioTimer({
              receiveMsg: data.data,
              chatUnreaded: gContext.twilioChat.chatConversationId && (document.visibilityState !== 'visible' || (!openChat.current && !fullscreen)),
              lastTimeActive: null,
            });
            break;
          default: break;
        }
      });
    }
  }, [gContext.twilioChat.chatCaseId]);
  
  useEffect(() => {
    if (typeof document !== "undefined") {
      if (fullscreen) {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
        document.documentElement.style.overflow = '';
      }
    }
  }, [fullscreen])

  return <> 
      { process.env.GATSBY_TWILIO_CHAT_VERSION === "2" &&
      <ChatWrapper className={side || 'right'}>
          { (openChat || fullscreen) && <Chat sc={sc} fullscreen={fullscreen} /> }
          <Widget sc={sc} fullscreen={fullscreen} />
      </ChatWrapper>
      }
  </>;
};

export default TwilioChat2;
