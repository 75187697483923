import React from "react";
import styled from 'styled-components';

const Header = styled.div`
    background-color: #08DE9E;
    width: 100%;
    padding: 15px;
    color: #ffffff;
    flex: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    -webkit-box-shadow: -2px 2px 5px 1px rgba(0,0,0,0.2);
    box-shadow: -2px 2px 5px 1px rgba(0,0,0,0.2);
    z-index: 91;
    img {
        width: 40px;
        height: 40px;
        -object-fit: fill;
        object-fit: fill;
        padding: 7px;
        border-radius: 200px;
        cursor: pointer;
        background-color: #FFFFFF;
        &.close {
            display: none;
            background-color: transparent;
        }
    }
    a {
        &:hover {
            text-decoration: none !important;
            cursor: pointer;
        }
    }
    span {
        padding: 0px;
    }
    @media (max-width: 576px) {
        img {
            &.close {
                display: block;
            }
        }
    }
`;

export default Header;