import React from "react";
import styled from 'styled-components';

const EventPost = styled.div`
    padding: 5px;
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    img {
        flex: 0;
        width: 40px;
        height: 40px;
    }
    span {
        display: block;
        background-color: #00468b;
        flex: 1;
        padding: 5px 15px;
        max-width: 80%;
        border-radius: 5px;
        color: #ffffff;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 100;
        text-align: center;
        flex-wrap: wrap;
        word-break: break-word;
        b {
        font-weight: 900;
        }
    }
`;

export default EventPost;