import { io, Socket } from 'socket.io-client';
import FormData from 'form-data';
import moment from 'moment';
export interface Message {
  createdAt: string;
  from?: string | null;
  files?: string | null;
  message: string | null;
  form: FormType | null;
  messageId: string;
  fromId: string;
  senderType: string;
}
export interface FormType {
  type: string;
  value: Array<{ title: string; action: string; placeholder?: string | null; type?: string; required?: boolean; options?:object }>;
}

export const fetchAsync = async (url = '', method = 'get', headers:object|null = {}, body:object|null = {}, statusCode = 201) => {
  try {
    const response = await fetch(process.env.GATSBY_API_URL + url, {
        method: method.toLowerCase(),
        headers: {
          "Authorization": `Bearer ${process.env.GATSBY_API_TOKEN}`,
          ...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
          ...headers
        },        
        // Convert the body to JSON only if it's not empty and FormData
        body: body ? ((body instanceof FormData) ? body as unknown as BodyInit : JSON.stringify(body)) : null
    });
    // Check if the response is okay
    if (!response) {
      console.error('Error fetching data: ', url);
        return null;
    }
    const data = await response.json();
    // Check if the statusCode matches
    return data?.statusCode === statusCode ? data : null;
  } catch (error) { console.error('Error fetching:', error); }
};

export const loadZipCodesAsync = async (input: string): Promise<{ value: string; label: string }[]> => {
    try {
      const data = await fetchAsync(`/CrmModule/v1.0/db/Premise2/search?terms=${input}&fields=properties.PostalCode,properties.PostalCodeNoSpace&schemas=${process.env.GATSBY_POSTCODE_SCHEMA_ID}&page=0&size=100&sort=[{%22properties.DoorNumber%22:{%22order%22:%22asc%22}},{%22properties.SPRN%22:{%22order%22:%22asc%22}}]`, 'get', null, null, 200);
      return data.data.map((category: any) => ({
        value: category.properties.PostalCode,
        label: category.properties.FullAddress,
      }));
    } catch (error) {
      console.error('Error fetching ZIP codes:', error);
      return [];
    }
};

export const getToken = async (email) => {
  try {
    const data = await fetchAsync(`/ChatModule/v1.0/gateway/token?identity=${email}&source=twilio`, 'get', null, null, 200);
    return data?.statusCode === 200 ? data?.data : null;
  } catch (error) { console.error('Error fetching token:', error); }
};

export const chatLocalStorage = async (name:string|null = null) => {
  try {
    const result = await localStorage.getItem('twilioChat');
    if(result){
      const response =  JSON.parse(result || '[]');
      return name ? response[name] : response;
    } else {
      return []
    }
  } catch (e) {
    console.log(e);
  }
};

export const timerLocalStorage = async (name:string|null = null) => {
  try {
    const result = await localStorage.getItem('twilioTimer');
    if(result){
      const response =  JSON.parse(result || '[]');
      return name ? response[name] : response;
    } else {
      return []
    }
  } catch (e) {
    console.log(e);
  }
};

export const didAgentPost = async (twilioChat) => {
  return twilioChat.messages && twilioChat.chatConversationId ? 
    Object.keys(twilioChat.messages.filter(ext => ext.senderType === 'AGENT')).length  > 1 : false;
};

export const scrollToBottom = () => {
  try {
    const scrollableContainer = document.querySelector('#ChatContentList');
    if(scrollableContainer){
      scrollableContainer.scrollTo({
        top: scrollableContainer.scrollHeight + 100,
        behavior: 'smooth'
      });
    }
  } catch(e){ }
};

export const isConversationActive = async (chatConversationId) => {
  try {
    const response = await fetchAsync(`${process.env.GATSBY_API_URL}/ChatModule/v1.0/gateway/conversation/${chatConversationId}`, 'get', null, null, 200);
    // Check if the response is okay
    if (!response) {
      console.error("Can't check conversation!");
      return null;
    }
    return response?.data?.properties?.Status;
  } catch (error) { console.error('Error fetching conversation status: ', error); }
};

export const createConversation = async (
  tmpChatUsername:string|null,
  tmpChatEmail:string|null,
  tmpChatAddress:string|null,
  tmpChatLastBill:string|null,
  tmpChatUserType:string|null,
  tmpChatCategory:string|null
) => {    
  try {
    const response = await fetchAsync(`/ChatModule/v1.0/gateway/conversation/create`, 'post', null, 
      {
        source: "twilio",
        channel: "WEB_CHAT",
        identifier: tmpChatEmail || null,
        DpaCustomerName: tmpChatUsername || null,
        DpaCustomerEmail: tmpChatEmail || null,
        DpaCustomerAddress: tmpChatAddress || null,
        DpaCustomerLastBill: tmpChatLastBill || null,
        DpaCustomerType: tmpChatUserType || null,
        DpaCustomerCategory: tmpChatCategory || null
      }, 201);
    // Check if the response is okay
    if (!response) {
      console.error("Can't create conversation!");
      return null;
    }
    // Check if the statusCode matches
    return response?.statusCode === 201 ? response?.data : null;
  } catch (error) { console.error('Error creating conversation:', error); }
};

export const closeConversation = async (closedBy:string = "CUSTOMER", chatConversationId:string) => {
  try {
    const response = await fetchAsync(
      `/ChatModule/v1.0/gateway/conversation/${chatConversationId}/close`, 
      'post', 
      null, 
      { closedBy: closedBy }, 
      201
    );
    // Check if the response is okay
    if (!response) {
      console.error("Can't close conversation!");
      return null;
    }
    // Check if the statusCode matches
    return response?.statusCode === 201;
  } catch (error) { console.error('Error closing conversation:', error); }
};

export const sendMessage = async (message:string|null = null, twilioChat:any, senderType:string = 'CUSTOMER') => {
  if(message || (message && message.length > 0) || Object.keys(twilioChat.uploadedFiles).length > 0) {
    typeof document !== "undefined" && document.getElementById('inputMessage')?.focus();
    let formData;
    try {
      if(Object.keys(twilioChat.uploadedFiles).length > 0){
        let tmpFormData:FormData = new FormData();
          tmpFormData.append('conversationSid', twilioChat.chatConversationId);
          tmpFormData.append('message', message !== '' ? message : null);
          tmpFormData.append('from', twilioChat.chatUsername);
          tmpFormData.append('sender', senderType);
          try{
            Object.keys(twilioChat.uploadedFiles).length > 0 && twilioChat.uploadedFiles.map(async (item) => {
              tmpFormData.append('files', item);
            });  
          } catch (e){
    
          }
          formData = tmpFormData;
      } else {
        let tmpFormData:any = {
          conversationSid: twilioChat.chatConversationId,
          message: message,
          from: twilioChat.chatUsername,
          sender: 'CUSTOMER',
          files: null,
        };
        formData = tmpFormData;
      }
      // Send a Message to the Conversation
      try {      
        const data = await fetchAsync(`/ChatModule/v1.0/gateway/conversation/${twilioChat.chatConversationId}/message/send`, 'post', null, formData, 201);
        if(data?.statusCode === 201){   
          return data;
        } 
        else if(data?.statusCode === 500){
          console.error("Conversation is closed");
          return [];
        }
      } catch (error) {
        console.error('Send a Message:', error);
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  }
};
  
export const getAllMessages = async (chatConversationId) => {
  try{
    const response:any = await fetchAsync(`/NotificationModule/v2.0/records/Conversation/${chatConversationId}/links?targetEntities=["Message"]`, 'get', null, null, 200);
    if(response){
      let tmpMessages:Message[] = await Promise.all(
        response.data?.Message?.dbRecords.map(async item => ({
          createdAt: item?.createdAt,
          from: item?.properties?.From,
          files: await getFiles(item?.properties?.Files), 
          message: item?.properties?.Body,
          form: null,
          messageId: item?.id,
          fromId: item?.createdBy?.id,
          senderType: item.type === "EVENT" ? "EVENT" : item.properties.SenderType
        }))
      );         
      return tmpMessages ? [
        ...tmpMessages, {
        senderType: 'EVENT',
        createdAt: moment(new Date()).format(),
        from: 'YouFibre',
        message: "Waiting for the agent to join the chat.",
        form: null,
        files: [],
        messageId: `${Date.now()}-0000-0000-0000-000000000000`,
        fromId: 'YouFibre',
      }] : [];   
    }
  } catch (error) {
    console.error('Error fetching messages:', error);
    return [];
  }
};

export const findUnreadedMessages = async (oldMessages:Message[] = [], newMessages:Message[] = []) => {
    let tmpMessages:Message[] = await Promise.all(
        newMessages.filter(item => !oldMessages.some(obj => obj?.messageId === item?.messageId)));       
    return tmpMessages;
};  

export const connectToWebsocket = (sessionId:string|null = null) => {
  return io(`${process.env.GATSBY_TWILIO_WEBSOCKET}/ChatWebsocketModule/TwilioChatWebsocket/v1.0`, 
    { 
      path: '/ws',
      query: {
        clientId: sessionId 
      },
      reconnection: true,
      reconnectionAttempts: Infinity, // Retry indefinitely
      reconnectionDelay: 1000,       // Start with a 1-second delay
      reconnectionDelayMax: 5000,    // Cap the delay at 5 seconds
      timeout: 10000,                // Timeout after 10 seconds
    }); 
};

export const getFiles = async (files: String[] | null = null) => {
  try{
    let tmpFiles:any = [];
    if(files){
      const response:any = await fetchAsync(`/SchemaModule/v1.0/db/File/many?ids=${files}`, 'get', null, null, 200);
        // Check if the response is okay
      if (!response) {
        console.error("Can't close conversation!");
      } else {
        if(Object.keys(response?.data).length){
          response?.data?.map((item) => {
            tmpFiles = [...tmpFiles, {
              id: item?.id || "",
              type: item?.properties?.Mimetype || "",
              url: item?.properties?.Url || ""
            }];
          });
        }
        return (Object.keys(tmpFiles).length !== 0) ? tmpFiles : [];
      }
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching files:', error);
    return [];
  }
};
