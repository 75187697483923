import moment from 'moment';

export const defaultMessages = [
{
    createdAt: moment(new Date()).format(),
    message: null,
    fromId: "Client",
    senderType: "CUSTOMER",
    form: {
    type: "buttons",
    value: [
        {
        title: "I am the account holder",
        action: "old_customer"
        },
        {
        title: "I am not the account holder",
        action: "not_customer"
        },
        {
        title: "I am new here",
        action: "new_customer"
        },
    ]
    },
    messageId: `${Date.now()}-0000-0000-0000-000000000000`
},
{
    senderType: "AGENT",
    createdAt: moment(new Date()).format(),
    from: "YouFibre",
    fromId: "YouFibre",
    message: "Welcome to YouFibre!<br />We're going to ask you some questions to ensure we get you through to the right team, so please bear with us!<br />Are you the account holder?",
    form: null,
    messageId: `${Date.now()}-0000-0000-0000-000000000000`
}
];

export const initialTwilioChatState = {
  logged: false,
  messages: defaultMessages,
  chatCaseId: null,
  uploadArea: false,
  uploadedFiles: [],
  chatClosing: false,
  chatClosingType: null,
  chatLoading: false,
  chatUserId: null,
  chatUsername: null,
  confirmClosing: false,
  chatConversationId: null,
  chatSessionId: null,
  chatUserType: null,
  openChat: false,
  resetFormValues: false,
  token: null,
  typingAgent: false,
  typingCustomer: false,
  lightboxUrl: null,
  agentId: null,
}

export const initialTwilioTimerState = {
  receiveMsg: null,
  chatUnreaded: false,
  lastTimeActive: null,
}

export const initialTwilioSettingsState = {
  fullscreen: false,
  side: 'right',
}

export type UserType = 'NEW_CUSTOMER' | 'ACCOUNT_HOLDER' | 'NOT_ACCOUNT_HOLDER';

export const newAccountHolder = [
    {
      senderType: "CUSTOMER",
      createdAt: moment(new Date()).format(),
      from: "Client",
      fromId: "Client",
      message: 'I am new here',
      form: null,
      messageId: `${Date.now()}-0000-0000-0000-000000000001`
    },
    {
      senderType: "AGENT",
      createdAt: moment(new Date()).format(),
      from: "YouFibre",
      fromId: "YouFibre",
      message: "We're so happy to hear from you! So that we can provide the best possible support, please provide us with the following information.",
      form: null,
      messageId: `${Date.now()}-0000-0000-0000-000000000002`
    },
    {
      senderType: "CUSTOMER",
      createdAt: moment(new Date()).format(),
      from: "YouFibre",
      fromId: "YouFibre",
      message: null,
      form: {
        type: "form",
        value: [
          {
            title: "Name",
            action: "full_name",
            placeholder: null,
            type: 'text',
            required: true
          },
          {
            title: "Email",
            action: "email",
            placeholder: null,
            type: 'email',
            required: true
          },
          {
            title: "Enter your Postcode",
            action: "address",
            placeholder: "Example: SW1 4EN",
            type: 'zip',
            required: true
          },
          {
            title: "What do you need help with today?",
            action: "category",
            placeholder: null,
            type: 'select',
            required: true,
            options: {
              SALES: "Sales",
              TECH_SUPPORT: "Tech Support",
              COMPLAINT: "Complaint",
            },
          },
          {
            title: "Submit",
            action: "submit_form",
            type: 'button'
          }
        ]
      },
      messageId: `${Date.now()}-0000-0000-0000-000000000003`
    }
];

export const notAccountHolder = [
    {
      senderType: "CUSTOMER",
      createdAt: moment(new Date()).format(),
      from: "Client",
      fromId: "Client",
      message: 'I am not the account holder',
      form: null,
      messageId: `${Date.now()}-0000-0000-0000-000000000001`
    },
    {
      senderType: "AGENT",
      createdAt: moment(new Date()).format(),
      from: "YouFibre",
      fromId: "YouFibre",
      message: "We're so happy to hear from you! So that we can provide the best possible support, please provide us with the following information.",
      form: null,
      messageId: `${Date.now()}-0000-0000-0000-000000000002`
    },
    {
      senderType: "CUSTOMER",
      createdAt: moment(new Date()).format(),
      from: "YouFibre",
      fromId: "YouFibre",
      message: null,
      form: {
        type: "form",
        value: [
          {
            title: "Name",
            action: "full_name",
            placeholder: null,
            type: 'text',
            required: true
          },
          {
            title: "Email",
            action: "email",
            placeholder: null,
            type: 'email',
            required: true
          },
          {
            title: "Enter your Postcode",
            action: "address",
            placeholder: "Example: SW1 4EN",
            type: 'zip',
            required: true
          },
          {
            title: "What do you need help with today?",
            action: "category",
            placeholder: null,
            type: 'select',
            required: true,
            options: {
              SALES: "Sales",
              TECH_SUPPORT: "Tech Support",
              COMPLAINT: "Complaint",
            },
          },
          {
            title: "Submit",
            action: "submit_form",
            type: 'button'
          }
        ]
      },
      messageId: `${Date.now()}-0000-0000-0000-000000000003`
    }
];

export const yesAccountHolder = [
    {
      senderType: "CUSTOMER",
      createdAt: moment(new Date()).format(),
      from: "Client",
      fromId: "Client",
      message: 'I am the account holder',
      form: null,
      messageId: `${Date.now()}-0000-0000-0000-000000000001`
    },
    {
      senderType: "AGENT",
      createdAt: moment(new Date()).format(),
      from: "YouFibre",
      fromId: "YouFibre",
      message: "We're so happy to hear from you! So that we can provide the best possible support, please provide us with the following information.",
      form: null,
      messageId: `${Date.now()}-0000-0000-0000-000000000002`
    },
    {
      senderType: "CUSTOMER",
      createdAt: moment(new Date()).format(),
      from: "YouFibre",
      fromId: "YouFibre",
      message: null,
      form: {
        type: "form",
        value: [
          {
            title: "Name",
            action: "full_name",
            placeholder: null,
            type: 'text',
            required: true
          },
          {
            title: "Email",
            action: "email",
            placeholder: null,
            type: 'email',
            required: true
          },
          {
            title: "Enter your Postcode",
            action: "address",
            placeholder: "Example: SW1 4EN",
            type: 'zip',
            required: true
          },
          {
            title: "What was the total value of your last bill?",
            action: "last_bill",
            placeholder: 'Total amount of your last bill',
            type: 'number',
            required: true
          },
          {
            title: "What do you need help with today?",
            action: "category",
            placeholder: null,
            type: 'select',
            required: true,
            options: {
              SALES: "Sales",
              TECH_SUPPORT: "Tech Support",
              INSTALLS_AND_SERVICE_VISITS: "Installs & Service Visits",
              MANAGE_MY_ACCOUNT: "Manage My Account",
              BILLING: "Billing",
              CANCELLATION: "Cancellation",
              COMPLAINT: "Complaint",
            },
          },
          {
            title: "Submit",
            action: "submit_form",
            type: 'button'
          }
        ]
      },
      messageId: `${Date.now()}-0000-0000-0000-000000000003`
    }
];

export const categories = {
    SALES: "Sales",
    TECH_SUPPORT: "Tech Support",
    INSTALLS_AND_SERVICE_VISITS: "Installs & Service Visits",
    MANAGE_MY_ACCOUNT: "Manage My Account",
    BILLING: "Billing",
    CANCELLATION: "Cancellation",
    COMPLAINT: "Complaint",
};

export const userTypeLabels = {
    NEW_CUSTOMER: "New customer",
    ACCOUNT_HOLDER: "Account holder",
    NOT_ACCOUNT_HOLDER: "Not the account holder"
};