import React from "react";
import styled from 'styled-components';

const ChatWrapper = styled.div`
position: fixed;
bottom: 40px;
z-index: 101;
display: flex;
flex-direction: column;
align-items: end;
gap: 15px;

&.left {
    align-items: start;
    left: 40px;
}
&.right {
    align-items: end;
    right: 40px;
}
`;

export default ChatWrapper;