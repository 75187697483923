import React from "react";
import styled from 'styled-components';

const YFPost = styled.div`
    width: 100%;
    padding: 5px;
    flex: 0;
    display: flex;
    gap: 15px;
    img {
        flex: 0;
        width: 40px;
        height: 40px;
        -object-fit: fill;
        object-fit: fill;
        border-radius: 200px;
        padding: 9px;
        background-color: #ddf0fc;
    }
    span {
        color: #263238;
        background-color: #ddf0fc;
        flex: 1;
        padding: 1rem;
        border-radius: 5px;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 100;
        display: block;
        word-break: break-word;
        a, a:visited, a:active {
        font-size: 1rem !important;
        text-decoration: underline;
        color: #005095;
        }
    }
    button {
        border: 2px solid #08DE9E;
        cursor: pointer;
        &:hover {
        background-color: #08DE9E;
        }
    }
    small {
        font-size: 0.7rem !important;
        font-weight: 100;
        line-height: 0.8rem;
        color: #bcc8d0;
    }
    .action {
        width: 0.8rem;
        height: 0.8rem;
        opacity: 0.5;
    }
    .files {
        margin: 0.3rem 0rem 0rem 0rem !important;
        border-radius: 5px;
        background-color: #f4f7f9 !important;;
        padding: 0rem;
        overlay: none;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        align-content: flex-start !important;
        justify-content: space-evenly !important;
        align-items: flex-start !important;
        .file{
        width: 130px;
        height: 130px;
        object-fit: contain;
        opacity: 1;
        cursor: pointer;
        }
    }
`;

export default YFPost;