import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ListGroup, Collapse } from "react-bootstrap";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";
import GlobalContext from "../../../context/GlobalContext";
import { Location } from '@reach/router';

const NestedMenuContainer = styled.div`
  a {
    display: block;
    transition: all 0.3s ease-out;
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  .list-group-item {
    margin: 0px;
    & + .collapse:not(.show) {
      .list-group-item {
        border: none !important;
        border-width: 0 !important;
      }
    }
    & + .collapse.show {
      .list-group-item {
        &:first-child {
          border-top: none !important;
        }
      }
    }
  }
  .collapse + .list-group-item {
    border-top-width: 0;
  }
  /* .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 1px;
  } */
`;

const MenuItem = ({
  label,
  isExternal = false,
  name,
  items,
  depthStep = 20,
  depth = 0,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const hasSubItems = Array.isArray(items);

  const gContext = useContext(GlobalContext);

  return (
    <>
      {hasSubItems ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            cursor: pointer;
          `}
          onClick={() => setOpen(!open)}
          className="d-flex align-items-center justify-content-between"
        >
          <span>{label}</span>
          <span>{open ? <FaAngleDown /> : <FaAngleRight />}</span>
        </ListGroup.Item>
      ) : (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
          `}
        >
          {isExternal ? (
            <a
              href={`${name}`}
              onClick={() => {
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas();
                }
              }}
            >
              {label}
            </a>
          ) : (
            <Location>
              {({ location }) => {
                return (
                  (name === '#chatnow') ? <a style={{color: "#000000"}} onClick={() => (typeof window !==  "undefined") && document.getElementsByClassName('YFChat')[0].click()}>{label}</a> :
                  <Link
                    to={(name !== ' ' && name !== null) ? `/${name}/`: '/'}
                    className={(location.pathname.includes('/' + name) && (name !== '')) ? 'active-mobile-menu-item' : ''}
                    onClick={() => {
                      if (gContext.visibleOffCanvas) {
                        gContext.toggleOffCanvas();
                      }
                    }}
                  >
                    {label}
                  </Link>
                )
              }}
            </Location>
          )}
        </ListGroup.Item>
      )}

      {hasSubItems ? (
        <Collapse in={open}>
          <ListGroup>
            {items.map((subItem) => (
              <MenuItem
                key={subItem.name}
                depth={depth + 1}
                depthStep={depthStep}
                {...subItem}
              />
            ))}
          </ListGroup>
        </Collapse>
      ) : null}
    </>
  );
};

const NestedMenu = ({ menuItems }) => {
  return (
    <NestedMenuContainer>
      <ListGroup variant="flush" className="mobileNav">
        {menuItems.map((menuItem, index) => (
          <MenuItem
            name={menuItem.name}
            key={`${menuItem.name}${index}`}
            depthStep={20}
            depth={0}
            {...menuItem}
          />
        ))}
      </ListGroup>
    </NestedMenuContainer>
  );
};

export default NestedMenu;
