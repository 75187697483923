import React from "react";
import styled from 'styled-components';

const Container = ({className = '', children}) => {
    return (
        <ContainerBlock className={className}>
          { children }
        </ContainerBlock>
    );
};

const ContainerBlock = styled.div`
  width: 100%;
  max-width: 700px;
  height: 100%;
  position: relative;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: center;  
  &.direction-column-reverse { flex-direction: column-reverse; }
  &.content-end { justify-content: end; }
`;

export default Container;