import React from "react";
import styled from 'styled-components';

const MyPost = styled.div`
    width: 100%;
    padding: 5px;
    flex: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    img {
        flex: 0;
        width: 40px;
        height: 40px;
        opacity: 0;
    }
    span, button {
        display: block;
        background-color: #c1f7e7;
        flex: 0;
        padding: 5px;
        border-radius: 5px;
        color: #263238;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 100;
        min-width: 40%;
        word-break: break-word;
        b {
        font-weight: 900;
        }
        a, a:visited, a:active {
        font-size: 1rem !important;
        text-decoration: underline;
        color: #397040;
        }
    }
    input, select {
        font-size: 0.9rem;
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        padding: 1px 4px;
        height: 30px;
        outline: none;
        &.invalid {
        border-bottom: 1px solid red;
        }
        &:focus{
        border-radius: 5px;
        }
        &:disabled{
        border: 1px solid #d9d9d9;
        }
    }
    select {
        cursor: pointer;
        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 98% 50%;
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
    }
    .loader {
        opacity: 1;
    }
    button {
        border: 1px solid #08DE9E;
        cursor: pointer;
        &:hover {
        background-color: #08DE9E;
        }
        &:disabled, &:disabled:hover {
        border: 1px solid #e1e1e1;
        background-color: #e1e1e1;
        cursor: default;
        }
    }
    small {
        font-size: 0.7rem !important;
        font-weight: 100;
        line-height: 0.8rem;
        color: #bcc8d0;
    }
    .action {
        width: 0.8rem;
        height: 0.8rem;
        opacity: 0.5;
    }
    .files {
        margin: 0rem !important;
        border-radius: 5px;
        background-color: #f4f7f9 !important;;
        padding: 0rem;
        overlay: none;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        align-content: flex-start !important;
        justify-content: space-evenly !important;
        align-items: flex-start !important;
        .file{
        width: 130px;
        height: 130px;
        object-fit: contain;
        opacity: 1;
        cursor: pointer;
        }
    }
`;

export default MyPost;