import React, { useContext, useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import GlobalContext from "../../../../context/GlobalContext";
import Message from "./Message";
import moment from 'moment';
import { categories, defaultMessages, newAccountHolder, notAccountHolder, userTypeLabels, yesAccountHolder } from "../utils/chats";
import { createConversation, getFiles, getToken, scrollToBottom, sendMessage } from "../services/chatService";
import TypingIndicator from "./TypingIndicator";
import ChatContentList from "./styled/ChatContentList";
import Splitter from "./styled/Splitter";

interface Message {
  createdAt: string;
  from?: string | null;
  files?: string | null;
  message: string | null;
  form: FormType | null;
  messageId: string;
  fromId: string;
  senderType: string;
}

interface FormType {
  type: string;
  value: Array<{ title: string; action: string; placeholder?: string | null; type?: string; required?: boolean; options?:object }>;
}
  
type UserType = 'NEW_CUSTOMER' | 'ACCOUNT_HOLDER' | 'NOT_ACCOUNT_HOLDER';

const MessageList = ({sc, fullscreen}) => {
  const gContext: any = useContext(GlobalContext);
  const [chatForm, setChatForm] = useState<{ [key: string]: any }>({});
  const [messages, setMessages] = useState<Message[]>(defaultMessages);
  const messagesRef = useRef<Message[]>(messages);

  const messageRef = useRef<Message[]>([]);
  const [message, setMessage] = useState<Message|null>(null);
  const [newMessage, setNewMessage] = useState<boolean>(false);
  
  useEffect(() => {
    setMessages(gContext.twilioChat.messages);
    messagesRef.current = gContext.twilioChat.messages;
  }, [gContext.twilioChat.messages]);

  useEffect(() => {
    if(gContext.twilioTimer.receiveMsg && !messageRef.current.some(obj => obj.messageId === gContext.twilioTimer.receiveMsg.id)){
      setNewMessage(false);
      receiveMessage(gContext.twilioTimer.receiveMsg, true);
    }
  }, [gContext.twilioTimer.receiveMsg]);
  
  useEffect(() => {
    if(message){
      messagesRef.current = messagesRef.current?.filter(item => !item.form);
      setMessages(messagesRef.current);
      scrollToBottom();
      const messageTimeout = setTimeout(() => {
        // Merge new messages with existing ones with unique messageId
        // messages: [...new Map(messagesRef.current.map(item => [item.messageId, item])).values()], 
        messagesRef.current.unshift(message);
        gContext.goSetTwilioChat({
          ...gContext.twilioChat, 
          messages: messagesRef.current
        });
        gContext.goSetTwilioTimer({
          receiveMsg: null,
          chatUnreaded: gContext.twilioChat.chatConversationId && (document.visibilityState !== 'visible' || (!gContext.twilioChat.openChat && !fullscreen)),
          lastTimeActive: Date.now(),
        });
        scrollToBottom();
        setMessage(null);
        clearTimeout(messageTimeout);
      }, 500);
    }
  }, [message]);
  
  let newMessageInterval:any;
  
  useEffect(() => {
    // Buffer for new messages
    if(newMessage && messageRef.current.length > 0 && !newMessageInterval){
      newMessageInterval = setInterval(() => {
        const first = messageRef.current.shift();
        setMessage(first || null);
        scrollToBottom();
        if(messageRef.current.length === 0){
          setNewMessage(false);
          clearInterval(newMessageInterval);
        }
      }, 800);   
    } else if (!newMessage) {
      setNewMessage(false);
      clearInterval(newMessageInterval);
    } else {

    }
  }, [newMessage]);
  
  useEffect(() => {
    setNewMessage(false);
    gContext.twilioChat.openChat && setNewMessage(true);
  }, [gContext.twilioChat.openChat]);
    
  const buttonAction = (action) => {
    setNewMessage(false);
    setTimeout(() => {
      switch(action){
        case 'new_customer': 
          gContext.goSetTwilioChat({...gContext.twilioChat, chatUserType: 'NEW_CUSTOMER'});
          messageRef.current = [...newAccountHolder];
          setNewMessage(true);
          break;
        case 'not_customer': 
          gContext.goSetTwilioChat({...gContext.twilioChat, chatUserType: 'NOT_ACCOUNT_HOLDER'});
          messageRef.current = [...notAccountHolder];
          setNewMessage(true);
          break;
        case 'old_customer': 
          gContext.goSetTwilioChat({...gContext.twilioChat, chatUserType: 'ACCOUNT_HOLDER'});
          messageRef.current = [...yesAccountHolder];
          setNewMessage(true);
          break;
        case 'submit_form':
            chatForm.full_name && 
            chatForm.address && chatForm.address !== '/' && 
            chatForm.email && 
            gContext.twilioChat.chatUserType && 
            (((gContext.twilioChat.chatUserType === "ACCOUNT_HOLDER") && typeof chatForm.last_bill !== "undefined") || (gContext.twilioChat.chatUserType !== "ACCOUNT_HOLDER")) &&
            chatForm.category && 
            submitForm(`<b>${userTypeLabels[gContext.twilioChat.chatUserType]}</b><br /><b>Full name</b>: ${chatForm.full_name}<br /><b>Email</b>: ${chatForm.email}<br /><b>Address</b>: ${chatForm.address}<br />${(typeof chatForm.last_bill !== 'undefined')?'<b>What was the total value of your last bill</b>: '+chatForm.last_bill+'<br />':''}<b>What do you need help with today</b>: ${categories[chatForm.category]}`);
          break;
        default: break;
      }
    }, 500);
  };

  const submitForm = async (message:string = '') => { 
    gContext.goSetTwilioChat({...gContext.twilioChat, 
      chatLoading: true,
      chatUsername: chatForm.full_name,
      chatEmail: chatForm.email,
      chatCategory: chatForm.category,
      chatAddress: chatForm.address,
      chatLastBill: chatForm.last_bill,
    });

    // Create Token  
    const tmpToken = await getToken(chatForm.email);
    if(tmpToken){
      gContext.goSetTwilioChat({...gContext.twilioChat, 
        token: tmpToken
      });
      // Create Conversation      
      const tmpConversation = await createConversation(
        chatForm.full_name,
        chatForm.email,
        chatForm.address,
        chatForm.last_bill,
        gContext.twilioChat.chatUserType,
        chatForm.category 
      );
      if(tmpConversation){
        gContext.goSetTwilioChat({...gContext.twilioChat, 
          logged: true,
          chatUserId: tmpConversation?.participantId,
          chatUsername: chatForm.full_name,
          chatConversationId: tmpConversation?.conversationId,
          token: tmpConversation?.caseId,
          chatCaseId: tmpConversation?.caseId,
          messages: [],
        });
        updateMessage({
          id: `${Date.now()}-0000-0000-0000-000000000000`,
          type: 'EVENT',
          title: 'Waiting for the agent to join the chat.'},
          null, true);
        sendMessage(message, {...gContext.twilioChat, chatConversationId: tmpConversation?.conversationId});         
      }
    } else {
      gContext.goSetTwilioChat({...gContext.twilioChat, chatLoading: false});
    }
  };
  
  const receiveMessage = async (data:any, resetTimer:boolean) => {
    if(data && !gContext.twilioChat.messages.some(obj => obj.messageId === data.id)){
      try{
        if(data.properties.Files){
          let tmpFiles:any = await getFiles(data.properties.Files);
          if(Object.keys(tmpFiles).length === 0) tmpFiles = null;
          updateMessage(data, tmpFiles, resetTimer);
        } else {
          updateMessage(data, [], resetTimer);
        }
      } catch (error) {
        console.error('Error fetching message:', error);
      }
    }
  };

  const updateMessage = (data: any, files:any, resetTimer:boolean) => {
    if(!messageRef.current.some(obj => obj.messageId === data.id)){
      let tmpMessage:Message = {
        senderType: data.type !== 'EVENT' ? data.properties.SenderType : data.type,
        createdAt: moment(new Date()).format(),
        from: data.type !== 'EVENT' ? data.properties.From : data.type,
        message: data.type !== 'EVENT' ? data.properties.Body : data.title,
        form: null,
        files: files,
        messageId: data.id,
        fromId: data.type !== 'EVENT' ? data.createdBy.id : "YouFibre"
      };
      scrollToBottom();
      messageRef.current.push(tmpMessage);
      !gContext.twilioTimer.chatUnreaded && setNewMessage(true);
    }
  };

  return (
    <ChatArea>
      <ChatContentList id='ChatContentList'>
        { sc?.current && <TypingIndicator sc={sc} /> }
        { message && <><Animation>       
            { Object.keys(messages).length !== 0  && <Splitter /> }  
            <Message item={message} buttonAction={buttonAction} setChatForm={setChatForm} />
          </Animation></>
        }
        { messages && messages.map((item, index) => 
          <>
          { item.senderType && <Message item={item} buttonAction={buttonAction} setChatForm={setChatForm} /> }
          { (Object.keys(messages).length - 1) !== index  && <Splitter /> }
          </>
        )}
      </ChatContentList>
    </ChatArea>
  );
};

const ChatArea = styled.div`
  width: 100%;
  flex: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
`;

const Animation = styled.div`
  &, .animation {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: inherit;
    margin-bottom: -4rem;
    animation: slideIn .4s cubic-bezier(0.41, 0.39, 0.53, 1.38) forwards;
  }
  @keyframes slideIn {
    from {
      margin-bottom: -4rem;
    }
    to {
      margin-bottom: 0rem;
    }
  }
`;

export default MessageList;
