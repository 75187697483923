import React from "react";
import styled from 'styled-components';

const ChatContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 350px;
    height: 550px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 2px 1px 12px 0px rgba(0, 0, 0, 0.35);
    max-width: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    &.show {
        max-width: 350px;
        max-height: 540px;
    }
    .cover {
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        z-index: 92;
        padding: 1rem;
        width: 100%;
        height: calc(100% - 65px);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        -webkit-user-select: none;
        user-select: none;
        text-align: center;
        &.closed {
        background: rgba(255, 255, 255, 0.9);
        button { margin-top: 2rem; }
        }
        &.upload {
        background: rgba(9, 222, 158, 0.9);
        button { margin-top: 2rem; }
        }
        animation: showCover .4s cubic-bezier(0.41, 0.39, 0.53, 1.38) forwards;
        
        @keyframes showCover {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
        }
    }
    .files {
        margin: 0.5rem;
        border-radius: 5px;
        background-color: #c1f7e7;
        padding: 0rem;
        overlay: none;
        display: flex;  
        flex-direction: column;
        justify-content: center;
        gap: 0rem;
        .file {
        display: flex;
        flex-direction: row;
        border-radius: 3px;
        justify-content: space-between;
        align-items: center;
        margin: 0.2rem;
        padding: 0.2rem 0.5rem;
        gap: 1rem;
        &:hover {
            background-color: #ffffff;
        }
        .fileName {
            font-size: 0.7rem !important;
            line-height: 0.8rem;
            word-break: keep-all;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .action {
            width: 0.8rem;
            height: 0.8rem;
            opacity: 0.7;
            cursor: pointer;
            &:hover {
            opacity: 1;
            }
        }

        }
    }
    &.fullscreen {
        max-width: 100% !important;
        max-height: 100% !important;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        // @media (max-width: 768px) {
        //   #listOfMessages {
        //     max-height: calc(100vh - 210px);
        //   }
        //   .chat-content-container {
        //     padding-bottom: 4rem;
        //   }
        // }
        .cover {
            &.closed {
                height: 100%;
            }
        }
    }
    @media (max-width: 576px) {
        position: fixed;
        z-index: 100;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        &.show {
        max-width: 100%;
        max-height: 100%;
        }
        .cover {
        &.closed, &.upload {
            height: 100%;
        }
        }
    }
`;

export default ChatContent;