import React from "react";
import styled from 'styled-components';

const InputForm = styled.form`
    background-color: #f4f7f9;
    width: 100%;
    flex: 0;
    
    .wrapper {
        width: 100%;
        max-width: 700px;
        position: relative;
        margin: 0px auto;
        flex: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 5px;
        .action {
            width: 1.3rem;
            height: 1.3rem;
            cursor: pointer;
            opacity: 0.7;
            -webkit-user-select: none;
            user-select: none;
            &:hover {
            opacity: 0.7;
            }
        }
        input {
            background-color: rgba(0,0,0,0);
            border: none;
            border-bottom: 1px solid rgba(0,0,0,0);
            outline: 0px;
            padding: 0px;
            margin-left: 15px;
            font-size: 0.9rem;
            font-weight: 100;
            flex: 1;
            &.invalid {
            border-bottom: 1px solid red;
            }
        }
        button {
            min-width: 90px;
            background-color: #09de9e;
            flex: 0;
            font-size: 1rem;
            &:hover {
            background-color: #08c98f;
            }
            &:disabled, &:disabled:hover {
            background-color: rgba(0,0,0,0.05);
            color: #aabbcc !important;
            }
        }
    }
    @media (max-width: 576px) {
        padding: 15px;
    }
`;


export default InputForm;